<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="10">
        <eb-date-range v-model="dateRange" @change="changeDate"></eb-date-range>
      </el-col>

      <el-col :span="4">
        <el-select
          v-model="query.userType"
          placeholder="开门类型"
          clearable
          filterable
        >
          <el-option label="代理开门" :value="3"></el-option>

          <el-option label="管理员开门" :value="2"></el-option>
        </el-select>
      </el-col>

      <el-col :span="4">
        <el-input placeholder="开门信息" v-model="query.info"></el-input>
      </el-col>

      <el-col :span="4">
        <el-input
          placeholder="电池编号"
          v-model="query.batteryMacId"
        ></el-input>
      </el-col>

      <el-col :span="4">
        <el-select
          v-model="query.stationMacId"
          placeholder="站点"
          clearable
          filterable
        >
          <template v-for="(item, index) in stationList.list">
            <el-option
              v-if="item.stationMacid != ''"
              :label="item.stationName"
              :value="item.stationMacId"
            ></el-option>
          </template>
        </el-select>
      </el-col>

      <el-col :span="4">
        <el-input v-model="query.userRealName" placeholder="用户姓名"> </el-input>
      </el-col>

      <el-col :span="4">
        <el-input v-model="query.doorId" placeholder="仓门"> </el-input>
      </el-col>

      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>
        <el-button
          type="primary"
          @click="doExportOpenDoorList"
          v-auth="this.per.EXPORT_OPEN_DOOR_RECORD"
          >导出开门记录</el-button
        >
      </el-col>
    </el-row>
    <OpenDoorRecord
      ref="OpenDoorRecord"
      :listQuery="query"
      :pageable="true"
    ></OpenDoorRecord>
  </div>
</template>

<script>
import OpenDoorRecord from "@/components/view-components/open-door-record";
import { mapState } from "vuex";
import moment from "moment";
import { exportOpenDoorList } from "@/api/order";

export default {
  components: { OpenDoorRecord },
  computed: mapState({
    stationList: (state) => {
      return state.station.stationList || { list: [], map: {} };
    },
  }),
  data() {
    let startTime = moment().startOf("M").format("YYYY-MM-DD HH:mm:ss");
    console.log(startTime);
    let endTime = moment().endOf("M").format("YYYY-MM-DD HH:mm:ss");
    return {
      dateRange: [startTime, endTime],
      stationName: [],
      dataSource: [],
      modalData: {},
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      query: {
        info: "",
        startTime: startTime,
        endTime: endTime,
      },
      total: 0,
    };
  },

  mounted() {
    this.getList();
    this.$store.dispatch("fetchStationAll");
  },

  methods: {
    doExportOpenDoorList() {
      let queryData = {
        startTime: this.query.startTime,
        endTime: this.query.endTime,
        stationId: -1,
        userId: -1,
      };
      exportOpenDoorList(queryData).then((res) => {
        this.util.apiDownLoadFile(
          res.data,
          "application/vnd.ms-excel",
          `${queryData.startTime}-${queryData.endTime}开门记录.xlsx`
        );
      });
    },
    changeDate(e) {
      if (!this.validatenull(e)) {
        this.query.startTime = moment(e[0]).format("YYYY-MM-DD HH:mm:ss");
        this.query.endTime = moment(e[1]).format("YYYY-MM-DD HH:mm:ss");
      } else {
        this.query.startTime = "";
        this.query.endTime = "";
      }
    },

    getList(current) {
      this.$refs["OpenDoorRecord"].getList(current);
    },
  },
};
</script>
